import React, { useState, useEffect } from 'react';

const GoogleIO: React.FC = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const targetDate = new Date('May 14, 2024 13:00:00').getTime();
      const now = new Date().getTime();
      const timeDifference = targetDate - now;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining({
          days,
          hours,
          minutes,
          seconds
        });
      }
    };

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const delayTransition = setTimeout(() => {
      setShowCountdown(true);
    }, 2000); // Adjust delay time as needed

    return () => clearTimeout(delayTransition);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center h-screen p-10">
      <div className={`transition-opacity duration-500 ${imageLoaded ? 'opacity-100' : 'opacity-0'} w-3/6 md:w-2/6 lg:w-1/6`}>
        <img
          className="max-w-full"
          src="https://io.google/2024/app/images/io24-homepage-hero-bg-dark.webp"
          alt="Google Logo"
          onLoad={() => setImageLoaded(true)}
        />
      </div>
      <div className={`transition-opacity duration-500 ${imageLoaded ? 'opacity-100' : 'opacity-0'} mt-10`}>
        {showCountdown ? (
          <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
          {timeRemaining.days <= 0 && timeRemaining.hours <= 0 && timeRemaining.minutes <= 0 && timeRemaining.seconds <= 0 ? (
            "May 14, 2024"
          )
          : timeRemaining.days > 0 ? (`${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`) 
          : timeRemaining.days === 0 && `${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`}
        </p>
        ) : (
          <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">May 14, 2024</p>
        )}
      </div>
    </div>
  );
};

export default GoogleIO;
